import Link from "next/link";
import Head from "next/head";
import {Fragment} from "react";

const Page404 = () => {
    return (
        <Fragment>
            <Head>
                <title>404 Error - No pudimos encontrar la página!</title>
            </Head>

            <div className="tt-offset-0 container-indent">
                <div className="tt-page404">
                    <div className="tt-page404-content">
                        <img width="440" src="/assets/images/no-placeholder/error.png" alt="404 not found error"/>
                        <h1 className="tt-title">Al parecer no hay nada por aquí.</h1>
                        <p>Algo salió mal y no pudimos encontrar la página.</p>
                        <Link href="/" className="btn">VOLVER A LA PÁGINA PRINCIPAL</Link>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Page404;